import React from 'react'
import cx from 'classnames'
import RoomIcon from '@material-ui/icons/Room';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import ShareIcon from '@material-ui/icons/Share';

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  icon: {
    color: ({ color }) => color || "inherit",
  }
}))

const ColumnIcon = ({ icon, size, color, className }) => {
  const classes = useStyles({ color })
  const renderIcon = (icon) => {
    switch(icon) {
      case 'mail' :
        return <MailIcon fontSize={size} className={cx(classes.icon, className)} />;
      case 'phone' :
        return <PhoneIcon fontSize={size} className={cx(classes.icon, className)} />;
      case 'social-media' :
        return <ShareIcon fontSize={size} className={cx(classes.icon, className)} />;
      default: 
        return <RoomIcon fontSize={size} className={cx(classes.icon, className)} />;
    }
  }
  
  return <>{renderIcon(icon)}</>
}

ColumnIcon.defaultProps = {
  size: 'large',
  className: ''
}

export default ColumnIcon
